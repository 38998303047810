<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card radius-lr10">
          <div class="card-header card-default">
            <div class="row">
              <div class="col-md-7">
                <p id="idExsampel" class="text-muted margin-b-5" >
                  Daftar Matakuliah & Dosen Pengampu
                </p>
              </div>
              <div class="col-md-5">
                <select class="selectTahun form-control form-control-rounded50" data-placeholder="Piliih Tahun Akademik" data-allow-clear="1"
                  v-model="dataSMT.smt_id">
                  <option v-for="smt in tahunAkademik" v-bind:value="smt.smt_id" :key="smt.smt_id">
                    {{ smt.smt_nama }}
                  </option>
                </select>
              </div>
            </div>
            
          </div>
          <div class="card-body padding-t-0 ">
            <div class="widget white-bg">
              <div class="row">
                <div class="col-md-4 col-xs-6 b-r"> <strong>{{ profil.nama_lengkap }}</strong>
                  <br>
                  <p class="text-muted">{{ profil.username }}</p>
                </div>
                <div class="col-md-4 col-xs-6 b-r"> <strong>Program Studi</strong>
                  <br>
                  <p class="text-muted">{{ profil.nama_prodi }}</p>
                </div>
                <div class="col-md-2 col-xs-6 b-r"> <strong>Tahun Akademik</strong>
                  <br>
                  <p class="text-muted">{{ dataSMT.smt_nm }}</p>
                </div>
                <div class="col-md-2 col-xs-6"> <strong>Jumlah SKS</strong>
                  <br>
                  <p class="text-muted">{{ jumlahSKS }}</p>
                </div>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <p>KODE</p>
                    <p>NAMA MATAKULIAH</p>
                  </th>

                  <th>AKSI</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(krs, num) in dataKRS" :key="krs.id_nil">
                  <td>{{ num + 1 }}</td>
                  <td>
                    <p class="text-small">{{ krs.kd_mak }}</p>
                    <p>{{ krs.nm_mak }}</p>
                  </td>
                  <td>
                     <router-link :to="{name: 'edominput', params: { id: krs.id_nil} }" class="btn btn-success box-shadow btn-rounded"><i class="fa fa-reply"></i> &nbsp;isi formulir</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import Loading from 'vue-loading-overlay'
import { getSMT } from '@/services/mahasiswa.service'
import { getKRS } from '@/services/krs.service'
import { useStore } from 'vuex'
export default {
  name: 'RiwayatKhs',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      dataKRS: [],
      tahunAkademik: [],
      dataSMT: {},
      profil: users,
      params: {
        smt_id: ''
      },
      isLoading: false,
      fullPage: true,
      onClikTahun: false,
      errorData: {}
    }
  },
  computed: {
    jumlahSKS: function () {
      var totKredit = 0
      const entriMHS = this.dataKRS
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    }
  },
  mounted () {
    var vm = this
    this.getTahunAkademik()
    this.getData()
    $('.selectTahun').select2({
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.params.smt_id = e.params.data.id
      vm.onClikTahun = true
      vm.getData()
    }).trigger('change')
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '293px',
      alwaysVisible: false
    })
  },
  methods: {
    async getTahunAkademik () {
      getSMT({}).then(response => {
        if (response.error_code === 200) {
          this.tahunAkademik = response.records
        }
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
      })
    },
    async getData () {
      if (this.onClikTahun) { this.isLoading = true }
      const params = {
        id_reg_pd: this.profil.id_reg_pd,
        th_smtmasuk: this.profil.id_smt_masuk,
        id_smt: this.params.smt_id
      }
      getKRS(params).then(response => {
        if (response.error_code === 200) {
          this.dataKRS = response.records
          this.dataSMT = response.tahun_akademik
          this.statusKRS = response.status
        }
        if (this.onClikTahun) { this.isLoading = false }
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        if (this.onClikTahun) { this.isLoading = false }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.td-dos {
  font-size: 0.85em;
  font-weight: 500;
}
</style>
