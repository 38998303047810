<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card radius-lr10">
          <div class="card-header card-default">
            <div class="row">
              <div class="col-md-7">
                <p id="idExsampel" class="text-muted margin-b-5">
                  Kuisoner Evaluasi Dosen Oleh Mahasiswa
                </p>
              </div>
            </div>
          </div>
          <template v-if="isShowKuis">
            <div class="card-body padding-t-0">
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-5 col-xs-6 b-r"> <strong>Nama Matakuliah</strong>
                    <br>
                    <p class="text-muted">{{ dataMak.nm_mak }}</p>
                  </div>
                  <div class="col-md-2 col-xs-6"> <strong>Kode MK / SKS</strong>
                    <br>
                    <p class="text-muted">{{ dataMak.kd_mak + ' / ' + dataMak.mk_sks }}</p>
                  </div>
                </div>
              </div>
              <div class="widget white-bg" style="padding-left: 0;">
                <div class="radio radio-success"  v-for="krs in dataKRS" :key="krs.id_nil">
                  <input type="radio" name="id_reg_ptk" v-bind:value="krs.id_dos" v-bind:id="krs.id_dos" v-model="inputName.id_reg_ptk">
                  <label v-bind:for="krs.id_dos"> {{ krs.nm_dos }} </label>
                </div>
              </div>
              <div class="widget bg-warning margin-b-5">
                <div class="row">
                  <div class="col-md-12 col-xs-12"> 
                      <strong>A.Perencanaan Perkuliahan</strong>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r"> 
                    <ol class="ul-num">
                      <li value="1">Dosen menjelaskan tentang garis besar program perkuliahan (silabus), rencana pembelajaran semester(RPS) dan Kontrak perkuliahan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="a1" id="id-a11" value="1"  v-model="inputName.a1" required>
                        <label for="id-a11"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a1" id="id-a12" value="2" v-model="inputName.a1" required>
                        <label for="id-a12"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a1" id="id-a13" value="3" v-model="inputName.a1" required>
                        <label for="id-a13"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a1" id="id-a14" value="4" v-model="inputName.a1" required>
                        <label for="id-a14"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a1" id="id-a15" value="5" v-model="inputName.a1" required>
                        <label for="id-a15"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r"> 
                    <ol class="ul-num">
                      <li value="2">Dosen menjelaskan tentang tujuan perkuliahan pada mata kuliah ini beserta cara evaluasi belajar mahasiswa.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="a2" id="id-a21" value="1" v-model="inputName.a2">
                        <label for="id-a21"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a2" id="id-a22" value="2" v-model="inputName.a2">
                        <label for="id-a22"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a2" id="id-a23" value="3" v-model="inputName.a2">
                        <label for="id-a23"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a2" id="id-a24" value="4" v-model="inputName.a2">
                        <label for="id-a24"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a2" id="id-a25" value="5" v-model="inputName.a2">
                        <label for="id-a25"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r"> 
                    <ol class="ul-num">
                      <li value="3">Dosen memberitahukan buku bacaan yang wajib dibaca oleh mahasiswa yang berhubungan dengan mata kuliah.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="a3" id="id-a31" value="1" v-model="inputName.a3">
                        <label for="id-a31"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a3" id="id-a32" value="2" v-model="inputName.a3">
                        <label for="id-a32"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a3" id="id-a33" value="3" v-model="inputName.a3">
                        <label for="id-a33"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a3" id="id-a34" value="4" v-model="inputName.a3">
                        <label for="id-a34"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="a3" id="id-a35" value="5" v-model="inputName.a3">
                        <label for="id-a35"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget bg-indigo margin-b-5">
                <div class="row">
                  <div class="col-md-12 col-xs-12"> 
                      <strong>B. Pelaksanaan Perkuliahan</strong>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r"> 
                    <ol class="ul-num">
                      <li value="1">Kesesuaian dengan jadwal dan GBPP/kontrak perkuliahan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b1" id="id-b11" value="1" v-model="inputName.b1">
                        <label for="id-b11"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b1" id="id-b12" value="2" v-model="inputName.b1">
                        <label for="id-b12"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b1" id="id-b13" value="3" v-model="inputName.b1">
                        <label for="id-b13"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b1" id="id-b14" value="4" v-model="inputName.b1">
                        <label for="id-b14"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b1" id="id-b15" value="5" v-model="inputName.b1">
                        <label for="id-b15"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r"> 
                    <ol class="ul-num">
                      <li value="2">Lama kuliah sesuai dengan jam yang ditetapkan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b2" id="id-b21" value="1" v-model="inputName.b2">
                        <label for="id-b21"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b2" id="id-b22" value="2" v-model="inputName.b2">
                        <label for="id-b22"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b2" id="id-b23" value="3" v-model="inputName.b2">
                        <label for="id-b23"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b2" id="id-b24" value="4" v-model="inputName.b2">
                        <label for="id-b24"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b2" id="id-b25" value="5" v-model="inputName.b2">
                        <label for="id-b25"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r"> 
                    <ol class="ul-num">
                      <li value="3">Kualitas Handout yang diberikan dosen saat kuliah.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b3" id="id-b31" value="1" v-model="inputName.b3">
                        <label for="id-b31"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b3" id="id-b32" value="2" v-model="inputName.b3">
                        <label for="id-b32"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b3" id="id-b33" value="3" v-model="inputName.b3">
                        <label for="id-b33"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b3" id="id-b34" value="4" v-model="inputName.b3">
                        <label for="id-b34"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b3" id="id-b35" value="5" v-model="inputName.b3">
                        <label for="id-b35"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="4">Cara dosen dalam menjawab pertanyaan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b4" id="id-b41" value="1" v-model="inputName.b4">
                        <label for="id-b41"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b4" id="id-b42" value="2" v-model="inputName.b4">
                        <label for="id-b42"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b4" id="id-b43" value="3" v-model="inputName.b4">
                        <label for="id-b43"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b4" id="id-b44" value="4" v-model="inputName.b4">
                        <label for="id-b44"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b4" id="id-b45" value="5" v-model="inputName.b4">
                        <label for="id-b45"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="5">Waktu yang diberikan dosen pada mahasiswa untuk mengajukan pertanyaan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b5" id="id-b51" value="1" v-model="inputName.b5">
                        <label for="id-b51"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b5" id="id-b52" value="2" v-model="inputName.b5">
                        <label for="id-b52"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b5" id="id-b53" value="3" v-model="inputName.b5">
                        <label for="id-b53"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b5" id="id-b54" value="4" v-model="inputName.b5">
                        <label for="id-b54"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b5" id="id-b55" value="5" v-model="inputName.b5">
                        <label for="id-b55"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="6">Dosen memberi kesempatan untuk berkomunikasi dua arah.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b6" id="id-b61" value="1" v-model="inputName.b6">
                        <label for="id-b61"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b6" id="id-b62" value="2" v-model="inputName.b6">
                        <label for="id-b62"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b6" id="id-b63" value="3" v-model="inputName.b6">
                        <label for="id-b63"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b6" id="id-b64" value="4" v-model="inputName.b6">
                        <label for="id-b64"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b6" id="id-b65" value="5" v-model="inputName.b6">
                        <label for="id-b65"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="7">Materi yang diberikan dosen terkait dengan masalah-masalah nyata dan aktual.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b7" id="id-b71" value="1" v-model="inputName.b7">
                        <label for="id-b71"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b7" id="id-b72" value="2" v-model="inputName.b7">
                        <label for="id-b72"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b7" id="id-b73" value="3" v-model="inputName.b7">
                        <label for="id-b73"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b7" id="id-b74" value="4" v-model="inputName.b7">
                        <label for="id-b74"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b7" id="id-b75" value="5" v-model="inputName.b7">
                        <label for="id-b75"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="8">Kualitas bahan ajar perkuliahan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b8" id="id-b81" value="1" v-model="inputName.b8">
                        <label for="id-b81"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b8" id="id-b82" value="2" v-model="inputName.b8">
                        <label for="id-b82"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b8" id="id-b83" value="3" v-model="inputName.b8">
                        <label for="id-b83"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b8" id="id-b84" value="4" v-model="inputName.b8">
                        <label for="id-b84"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b8" id="id-b85" value="5" v-model="inputName.b8">
                        <label for="id-b85"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="9">Penguasaan dosen terhadap subtansial mata kuliah.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b9" id="id-b91" value="1" v-model="inputName.b9">
                        <label for="id-b91"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b9" id="id-b92" value="2" v-model="inputName.b9">
                        <label for="id-b92"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b9" id="id-b93" value="3" v-model="inputName.b9">
                        <label for="id-b93"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b9" id="id-b94" value="4" v-model="inputName.b9">
                        <label for="id-b94"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b9" id="id-b95" value="5" v-model="inputName.b9">
                        <label for="id-b95"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="10">Sistematika materi yang disampaikan oleh dosen.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b10" id="id-b101" value="1" v-model="inputName.b10">
                        <label for="id-b101"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b10" id="id-b102" value="2" v-model="inputName.b10">
                        <label for="id-b102"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b10" id="id-b103" value="3" v-model="inputName.b10">
                        <label for="id-b103"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b10" id="id-b104" value="4" v-model="inputName.b10">
                        <label for="id-b104"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b10" id="id-b105" value="5" v-model="inputName.b10">
                        <label for="id-b105"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="11">Pemanfaatan sumber belajar dan media perkuliahan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b11" id="id-b111" value="1" v-model="inputName.b11">
                        <label for="id-b111"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b11" id="id-b112" value="2" v-model="inputName.b11">
                        <label for="id-b112"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b11" id="id-b113" value="3" v-model="inputName.b11">
                        <label for="id-b113"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b11" id="id-b114" value="4" v-model="inputName.b11">
                        <label for="id-b114"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b11" id="id-b115" value="5" v-model="inputName.b11">
                        <label for="id-b115"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="12">Metode yang digunakan dalam perkuliahan.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b12" id="id-b121" value="1" v-model="inputName.b12">
                        <label for="id-b121"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b12" id="id-b122" value="2" v-model="inputName.b12">
                        <label for="id-b122"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b12" id="id-b123" value="3" v-model="inputName.b12">
                        <label for="id-b123"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b12" id="id-b124" value="4" v-model="inputName.b12">
                        <label for="id-b124"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b12" id="id-b125" value="5" v-model="inputName.b12">
                        <label for="id-b125"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="13">Pengelolaan kelas oleh dosen.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="b13" id="id-b131" value="1" v-model="inputName.b13">
                        <label for="id-b131"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b13" id="id-b132" value="2" v-model="inputName.b13">
                        <label for="id-b132"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b13" id="id-b133" value="3" v-model="inputName.b13">
                        <label for="id-b133"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b13" id="id-b134" value="4" v-model="inputName.b13">
                        <label for="id-b134"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b13" id="id-b135" value="5" v-model="inputName.b13">
                        <label for="id-b135"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget bg-success margin-b-5">
                <div class="row">
                  <div class="col-md-12 col-xs-12">
                      <strong>C. Evaluasi Perkuliahan</strong>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="1">Objektivitas dosen dalam penilaian hasil belajar mahasiswa.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="c1" id="id-c11" value="1" v-model="inputName.c1">
                        <label for="id-c11"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c1" id="id-c12" value="2" v-model="inputName.c1">
                        <label for="id-c12"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c1" id="id-c13" value="3" v-model="inputName.c1">
                        <label for="id-c13"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c1" id="id-c14" value="4" v-model="inputName.c1">
                        <label for="id-c14"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c1" id="id-c15" value="5" v-model="inputName.c1">
                        <label for="id-c15"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="2">Kualitas soal ujian dan kesesuaian materi kuliah.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="c2" id="id-c21" value="1" v-model="inputName.c2">
                        <label for="id-c21"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c2" id="id-c22" value="2" v-model="inputName.c2">
                        <label for="id-c22"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c2" id="id-c23" value="3" v-model="inputName.c2">
                        <label for="id-c23"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c2" id="id-c24" value="4" v-model="inputName.c2">
                        <label for="id-c24"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="b2" id="id-c25" value="5" v-model="inputName.c2">
                        <label for="id-c25"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="3">Manfaat dari mata kuliah ini bagi anda.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="c3" id="id-c31" value="1" v-model="inputName.c3">
                        <label for="id-c31"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c3" id="id-c32" value="2" v-model="inputName.c3">
                        <label for="id-c32"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c3" id="id-c33" value="3" v-model="inputName.c3">
                        <label for="id-c33"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c3" id="id-c34" value="4" v-model="inputName.c3">
                        <label for="id-c34"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c3" id="id-c35" value="5" v-model="inputName.c3">
                        <label for="id-c35"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget white-bg">
                <div class="row">
                  <div class="col-md-9 col-xs-6 b-r">
                    <ol class="ul-num">
                      <li value="4">Hasil ujian dikembalikan kepada mahasiswa.</li>
                    </ol>
                  </div>
                  <div class="col-md-3 col-xs-6">
                    <div class="form-inline float-right">
                      <div class="radio radio-success">
                        <input type="radio" name="c4" id="id-c41" value="1" v-model="inputName.c4">
                        <label for="id-c41"> 1 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c4" id="id-c42" value="2" v-model="inputName.c4">
                        <label for="id-c42"> 2 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c4" id="id-c43" value="3" v-model="inputName.c4">
                        <label for="id-c43"> 3 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c4" id="id-c44" value="4" v-model="inputName.c4">
                        <label for="id-c44"> 4 </label>
                      </div>
                      <div class="radio radio-success">
                        <input type="radio" name="c4" id="id-c45" value="5" v-model="inputName.c4">
                        <label for="id-c45"> 5 </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Komentar</label>
                <textarea class="form-control" v-model="inputName.komentar" rows="4" cols="50"></textarea>
              </div>

              <button type="button" v-on:click="submit" class="btn btn-success btn-rounded box-shadow">Simpan Kuisoner</button> &nbsp;
              <router-link to="/edom/matakuliah" class="btn btn-warning btn-rounded box-shadow">Kembali</router-link>
            </div>
          </template>
          <template v-else>
            <div class="card-body padding-t-0">
              <div class="alert bg-success alert-dismissible" role="alert"> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Terimakasih!. Kuisoner ini sudah diisi </div>
              <router-link to="/edom/matakuliah" class="btn btn-warning btn-rounded box-shadow margin-b-20">Kembali</router-link>
              <div class="widget white-bg widget-b-0">
                <div class="row">
                  <div class="col-md-5 col-xs-6 b-r"> <strong>Nama Matakuliah</strong>
                    <br>
                    <p class="text-muted">{{ dataMak.nm_mak }}</p>
                  </div>
                  <div class="col-md-2 col-xs-6"> <strong>Kode MK / SKS</strong>
                    <br>
                    <p class="text-muted">{{ dataMak.kd_mak + ' / ' + dataMak.mk_sks }}</p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */

import Loading from 'vue-loading-overlay'
import { getEdom, addEdom } from '@/services/edom.service'
import { getPengampu } from '@/services/krs.service'
import { useStore } from 'vuex'
export default {
  name: 'KuisonerEdom',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      dataKRS: [],
      dataMak: {},
      dataEdom: [],
      profil: users,
      params: {
        smt_id: ''
      },
      inputName: {
        id_reg_pd: users.id_reg_pd,
        id_reg_ptk: '',
        id_smt: '',
        id_sms: users.id_prodi,
        a1: null,
        a2: null,
        a3: null,
        b1: null,
        b2: null,
        b3: null,
        b4: null,
        b5: null,
        b6: null,
        b7: null,
        b8: null,
        b9: null,
        b10: null,
        b11: null,
        b12: null,
        b13: null,
        c1: null,
        c2: null,
        c3: null,
        komentar: ''
      },
      isLoading: false,
      fullPage: true,
      isShowKuis: true,
      onClikTahun: false,
      errorData: {}
    }
  },
  computed: {
    jumlahSKS: function () {
      var totKredit = 0
      const entriMHS = this.dataKRS
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    }
  },
  mounted () {
    var vm = this
    this.getData()
    this.dataPengampu()
    $('.selectTahun').select2({
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.params.smt_id = e.params.data.id
      vm.onClikTahun = true
    }).trigger('change')
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '293px',
      alwaysVisible: false
    })
  },
  methods: {
    async getData () {
      const params = {
        id_reg_pd: this.profil.id_reg_pd,
        id_nil: this.$route.params.id
      }
      getEdom(params).then(response => {
        if (response.error_code === 200) {
          const row = response.records
          const jumData = row.length
          if (jumData > 0) {
            this.isShowKuis = false
          }
          this.dataEdom = row
        }
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
      })
    },
    async dataPengampu () {
      this.isLoading = true
      const params = {
        id_reg_pd: this.profil.id_reg_pd,
        id_nil: this.$route.params.id
      }
      getPengampu(params).then(response => {
        if (response.error_code === 200) {
          const row = response.records
          const numRow = row.length
          if (numRow > 0) {
            this.dataKRS = row
            this.dataMak = row[0]
          } else {
            this.dataMak = {
              id_nil: '',
              id_mak: '',
              id_smt: '',
              kd_mak: '',
              mk_sks: '',
              mk_kur: '',
              mk_smt: '',
              nm_dos: '',
              id_dos: ''
            }
          }
        }
        this.isLoading = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    },
    async submit () {
      this.isLoading = true
      this.inputName.id_smt = this.dataMak.id_smt
      this.inputName.id_mak = this.dataMak.id_mak
      addEdom(this.inputName).then(response => {
        if (response.error_code === 200) {
          $.toast({
            heading: 'Good job!',
            text: 'Kuisoner evaluasi berhasil diisi',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'success',
            hideAfter: 3000,
            stack: 1
          })
          this.isLoading = false
          setTimeout(() => {
            this.$router.push('/edom/matakuliah')
          }, 1000)
        } else {
          $.toast({
            heading: 'Error !',
            text: response.messages,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
          this.isLoading = false
        }
      }).catch(error => {
        this.isLoading = false
        this.errorData = (error.response) ? {} : {}
      })
    }
  }
}
</script>
<style scoped lang="scss">
.ul-num {
  padding-left: 10px;
}
.float-right {
  float: right;
}
.widget-b-0 {
  padding-bottom: 0;
  margin-bottom: 2px;
}

</style>
