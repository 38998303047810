import request from '../config/request'

export function getKRS (parameter) {
  return request.post('/akademik/krs', parameter)
}

export function addKRS (parameter) {
  return request.post('/akademik/entrikrs', parameter)
}

export function cetKRS (parameter) {
  return request.get('/download/krs', { params: parameter, responseType: 'blob' })
}

export function getPengampu (parameter) {
  return request.post('/akademik/dosenpengampu', parameter)
}

export function getKurikulum (parameter) {
  return request.post('/akademik/kurikulum', parameter)
}
